.stamp {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
}

.stamp span {
  flex: 1;
}

.stamp span input {
  width: 90%;
}

.myModal {
  max-width: 90vw;
  height: 100%;
}

.myModal > div {
  height: 90%;
}

.myModalHeader {
  padding: 1vh 1rem;
}

.myModalTitle {
  font-size: 2vh;
}

.header {
  background-color: #ddd;
}

.myModal img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: 100%;
}
