.region {
  display: flex;
  align-items: baseline;
}

.region > div {
  flex: 1;
}

.region > section {
  flex: 5;
}

.twoColumns {
  display: flex;
  align-items: flex-start;
}

.twoColumns > div:first-child {
  flex: 1;
}
.twoColumns > div:last-child {
  flex: 3;
}

.year {
  display: inline-block;
  width: 60px;
  border: 1px solid #555;
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}

.year:hover {
  background-color: rgb(56, 73, 128);
  color: white;
}
