.setSelect > div > span {
  display: inline-block;
  margin-left: 15px;
  text-indent: -15px;
}

.setSelect > div:hover {
  background-color: antiquewhite;
}

.twoColumns {
  display: flex;
}

.twoColumns > div:first-child {
  flex: 1;
  margin-right: 10px;
  border-right: 1px dashed #ddd;
}
.twoColumns > div:last-child {
  flex: 3;
}

.row {
  display: flex;
  align-items: baseline;
}

.row > div {
  flex: 1;
}

.row > section {
  flex: 5;
}

.stampDetail {
  margin-top: 20px;
  border: 1px solid rgb(206, 225, 240);
  border-radius: 10px;
  padding: 10px;
}

.setSelect a {
  display: inline-block;
  margin-left: 15px;
  text-indent: -15px;
  color: black;
  padding: 5px;
  border-radius: 5px;
}

.setSelect a:hover {
  background-color: antiquewhite;
  text-decoration: none;
}

.setSelect .active {
  background-color: rgb(252, 181, 89);
  border-radius: 5px;
}