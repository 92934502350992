.toggle {
  padding: 0 4px;
  background-color: rgb(110, 176, 238);
  color: white;
  cursor: pointer;
}

.label {
  cursor: pointer;
  margin: 0 10px;
}
.label:hover {
  background-color: #ddd;
}

.tree .subtree {
  margin-left: 10px;
}
