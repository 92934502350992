.editable {
  display: inline;
  border: 1px solid transparent;
}

.editable:hover {
  background-color: azure;
  border: 1px dashed green;
  border-radius: 3px;
}

.editable > div {
  display: inline;
}

.editable input {
  width: 100%;
}

.editable span {
  display: block;
  padding: 3px;
}

.placeholder {
  font-style: italic;
  color: #ccc;
}
