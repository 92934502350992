/** @format */

.stamp {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  min-height: 15px;
  margin-top: 5px;
}

.stamp:hover {
  background-color: antiquewhite;
}

.stamp > span {
  display: inline-block;
  flex: 1;
}

.stamp > span:nth-child(3) {
  flex: 3;
}

.stamp button {
  margin-left: 2px;
  max-height: 36px;
}

.myModal {
  max-width: 90vw;
  height: 100%;
}

.myModal > div {
  height: 90%;
}

.myModalHeader {
  padding: 1vh 1rem;
}

.myModalTitle {
  font-size: 2vh;
}

.header {
  background-color: #ddd;
}

.myModal img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: 100%;
}

.arrow {
  flex: 0.2 !important;
  background-color: rgb(196, 202, 219);
  border: 1px solid blue;
  border-radius: 5px;
  text-align: center;
  height: 25px;
  cursor: pointer;
}

.arrow :hover {
  background-color: rgb(133, 155, 228);
}
