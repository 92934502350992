.paginator {
  background-color: #ddd;
}

.paginator span {
  margin: 3px;
  padding: 3px;
  cursor: pointer;
}

.paginator span.selected {
  color: red;
}

.paginator span:hover {
  background-color: #8dd;
}
