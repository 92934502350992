.album {
    display: inline-block;
    width: 6rem;
    border: 1px solid rgb(56, 73, 128);
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    cursor: pointer;
    text-align: center;
}

.album:hover {
    background-color: rgb(56, 73, 128);
    color: white;
}