.row {
  display: flex;
  align-items: baseline;
}

.row > div {
  flex: 1;
}

.row > div:last-child {
  flex: 5;
}

.row > section {
  flex: 5;
}

.set {
  margin: 10px 0px;
  border: 1px solid green;
  border-radius: 10px;
}

.set > div {
  padding: 2px 10px;
}

.set > div:first-child {
  background-color: #ddd;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
}

.yearSelect > span {
  display: inline-block;
  width: 5rem;
  border: 1px solid rgb(56, 73, 128);
  border-radius: 5px;
  margin: 10px;
  padding: 5px 10px;
  cursor: pointer;
  text-align: center;
}

.yearSelect > span:hover {
  background-color: rgb(56, 73, 128);
  color: white;
}

.yearSelect .selected {
  background-color: rgb(128, 130, 136);
}
