.card {
    background-color: rgb(86, 96, 129);
    color: white;
    padding: 10px;
    height: 8rem;
    display: inline-block;
    width: 16rem;
    text-align: center;
    border-radius: 0 2rem 2rem 0;
    margin: 10px;
    cursor: pointer;
    vertical-align: top;
}

.card:hover {
    background-color: rgb(56, 73, 128);
}

.title {
    font-size: 1.7rem;
    font-weight: bold;
}

.card a {
    color: white;
}

.card a:link, .card a:visited, .card a:active {
    text-decoration: none;
}

@media (max-width: 768px) {
    .card {
        width: calc(.2rem + (100vw / 3));
        font-size: calc(.1rem + (100vw / 50));
        height: calc(.1rem + (100vw / 4));
    }

    .title {
        font-size: calc(.5rem + (100vw / 37));
    }
}