.user {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.user .id {
    flex: 2;
}

.user .email {
    flex: 2;
}

.user div {
    flex: 1;
}